import { Avatar, AvatarGroup, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material'
import { useNavigate } from 'react-router'

export const CircleItem = ({ circle }) => {
	const navigate = useNavigate()
	return (
		<ListItem disablePadding>
			<ListItemButton onClick={() => navigate('/app/circles/' + circle.id)}>
				<ListItemAvatar sx={{ mr: 2, minWidth: 0 }}>
					<AvatarGroup max={4}>
						{circle.members.map((user) => (
							<Avatar
								key={user.id}
								alt={user.name}
								src={user.pic}
							/>
						))}
					</AvatarGroup>
				</ListItemAvatar>
				<ListItemText
					primary={circle.name}
					primaryTypographyProps={{ color: 'text.primary' }}
				/>
			</ListItemButton>
		</ListItem>
	)
}
