import { useQuery } from '@apollo/client'
import GET_WANNA_CHAT from '../gql/getWannaChat.gql'

export default (wannaId) => {
	const chatQuery = useQuery(GET_WANNA_CHAT, {
		variables: {
			id: wannaId,
		},
	})
	// const fetchMore = () => {
	// 	if (chatQuery.loading) return
	// 	// Apparently fetchMore is unable to read from the cache? So this isn't ideal, because when scrolling up
	// 	// they'll have to wait for it to load every time. Ah well.
	// 	// We could use a merge function in the cache but then we can't trigger fetching messages that may have updated
	// 	// while we didn't have a subscription but already had them cached.
	// 	// This way the latest messages will overwrite the entire cache, and then we can fetch older messages
	// 	// when triggered, to ensure we've got the latest version.
	// 	// We could merge results so that we never delete messages from the cache, but then
	// 	// we'd need to implement fetching updated versions of the messages as we scroll through them.
	// 	chatQuery.fetchMore({
	// 		variables: {
	// 			beforeId: chatQuery.data.wanna.messages[chatQuery.data.wanna.messages.length - 1]?.id
	// 		},
	// 		updateQuery: (prev, { fetchMoreResult }) => {
	// 			return {
	// 				wanna: {
	// 					...prev.wanna,
	// 					messages: [...prev.wanna.messages, ...fetchMoreResult.wanna.messages]
	// 				}
	// 			}
	// 		},

	// 	})
	// }

	return chatQuery.data?.wanna?.messages
}
