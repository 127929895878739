import { useQuery } from '@apollo/client'
import { AppBar, Toolbar, Stack, Typography } from '@mui/material'
import { useParams } from 'react-router'
import { GoBackButton } from '../../components/GoBackButton'
import { ToolbarTitle } from '../../components/ToolbarTitle'
import GET_WANNA_ACTIVITY from '../../gql/getWannaActivity.gql'
import { Chat } from './Chat.jsx'
import { ShareButton } from '../../components/ShareButton.jsx'
import { useMe } from '../../hooks/useMe'

export const Wanna = () => {
	const me = useMe()
	const wannaId = useParams().id

	const { data: { wanna } = {} } = useQuery(GET_WANNA_ACTIVITY, {
		variables: {
			id: wannaId,
		},
	})

	return (
		<Stack
			height='100%'
			minHeight={0}>
			<AppBar position='static'>
				<Toolbar>
					<GoBackButton />
					<ToolbarTitle>{wanna?.activity || ''}</ToolbarTitle>
					<ShareButton
						text={`Wanna ${wanna?.activity}?`}
						link={window.location.href}
					/>
				</Toolbar>
			</AppBar>
			<Chat wannaId={wannaId} />
		</Stack>
	)
}
