import AddIcon from '@mui/icons-material/Add'
import useAppBarHeight from '../../hooks/useAppBarHeight'
import TurnLeft from '@mui/icons-material/TurnLeft'
import { Box, Button, Fab, List, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { Blurb } from '../../components/Blurb'
import { WannaItem } from '../../components/WannaItem'
import { useConnectedWannas } from '../../hooks/useConnectedWannas'
import { cmpWannas } from '../../utils/utils'
import { HomeToolbar } from '../nav/HomeToolbar'
import { IWanna } from './IWanna'
import { ShareButton } from '../../components/ShareButton'
import { REFERRER_URL_PARAM } from '../../utils/constants'
import { useMe } from '../../hooks/useMe'

export const Wannas = () => {
	const me = useMe()
	const appBarHeight = useAppBarHeight()
	const [isCreateOpen, setCreateOpen] = useState(false)
	const navigate = useNavigate()
	const wannas = useConnectedWannas()

	return (
		<Stack height={'100%'}>
			<HomeToolbar />
			{wannas?.length ? (
				<List
					disablePadding
					sx={{ overflow: 'auto' }}>
					{wannas
						?.slice()
						?.sort(cmpWannas)
						?.map((wanna) => (
							<WannaItem
								key={wanna.id}
								wanna={wanna}
								onClick={() => navigate(`/app/wannas/${wanna.id}`)}
							/>
						))}
				</List>
			) : (
				<Box
					position='relative'
					height='100%'>
					<Stack
						direction='row'
						position='absolute'
						top={4}
						right={33}>
						<Typography color='text.secondary'>
							find friends
							<br />
							and circles
						</Typography>
						<TurnLeft sx={{ transform: 'rotate(90deg)', color: 'text.secondary' }} />
					</Stack>
					<Blurb
						text={"it's quiet in here... too quiet.\nwhy don't you:"}
						cta={
							<Stack
								alignItems={'stretch'}
								gap={1}>
								<Button
									variant='contained'
									color='secondary'
									onClick={() => setCreateOpen(true)}>
									take the initiative
								</Button>
								<ShareButton
									bigButtonText={'invite friends'}
									text='Come join me on Wanna!'
									link={`${window.location.origin}?${REFERRER_URL_PARAM}=${me?.id}`}
									sx={{ width: '100%' }}
								/>
							</Stack>
						}
					/>
				</Box>
			)}
			<IWanna
				isOpen={isCreateOpen}
				onClose={() => setCreateOpen(false)}
			/>
			<Fab
				sx={{
					right: 20,
					bottom: 20,
					position: 'absolute',
				}}
				variant='extended'
				onClick={() => setCreateOpen(true)}
				color='secondary'>
				<Typography
					color='white'
					textTransform={'none'}
					fontWeight={'bold'}>
					I wanna...
				</Typography>
			</Fab>
		</Stack>
	)
}
