import Share from '@mui/icons-material/Share'
import { Alert, Button, IconButton, Snackbar } from '@mui/material'
import { useState } from 'react'
import { share } from '../utils/utils'

export const ShareButton = ({ bigButtonText, text, link, height, width, sx, disabled }) => {
	const [open, setOpen] = useState(false)

	return (
		<div>
			{bigButtonText ? (
				<Button
					variant='outlined'
					sx={sx}
					disabled={disabled}
					endIcon={<Share sx={{ height, width }} />}
					onClick={() => setOpen(!share(text, link))}>
					{bigButtonText}
				</Button>
			) : (
				<IconButton
					sx={sx}
					disabled={disabled}
					onClick={() => setOpen(!share(text, link))}
					color='white'>
					<Share sx={{ height, width, color: 'white' }} />
				</IconButton>
			)}
			<Snackbar
				open={open}
				onClose={() => setOpen(false)}
				autoHideDuration={2000}>
				<Alert
					onClose={() => setOpen(false)}
					severity='info'
					sx={{ width: '100%' }}>
					copied share link to clipboard
				</Alert>
			</Snackbar>
		</div>
	)
}
