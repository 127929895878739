import { useEffect } from 'react'
import GET_ME from '../gql/getMe.gql'
import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'

export const useMe = (skipCache) => {
	const { data } = useQuery(GET_ME, {
		fetchPolicy: skipCache ? 'network-only' : 'cache-first',
	})

	useEffect(() => {
		const me = data?.me
		if (!me) return
		// TODO: need to put in window.onSentryLoad. 
		// But then that'd presumably override the one in index.html.
		Sentry.setUser({ id: me.id, name: me.name })
	}, [data?.me])

	return data?.me
}
