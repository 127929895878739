import { useEffect, useRef } from 'react'

// Allows for a cleanup function, that includes dependencies, to be run *only* when the component unmounts.
// Normally the cleanup function would also run when the dependencies change.
// https://stackoverflow.com/questions/55020041/react-hooks-useeffect-cleanup-for-only-componentwillunmount
export const useParamaterizedCleanup = (cleanup, deps) => {
	const componentWillUnmount = useRef(false)
	useEffect(() => {
		return () => {
			componentWillUnmount.current = true
		}
	}, [])
	useEffect(() => {
		return () => {
			if (componentWillUnmount.current) {
				cleanup()
			}
		}
	}, [deps])
}
