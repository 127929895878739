import PanTool from '@mui/icons-material/PanTool'
import { Chip } from '@mui/material'
import { forwardRef, useMemo } from 'react'
import { useMe } from '../hooks/useMe'
import { useParticipantships } from '../hooks/useParticipantships'
import { useRespond } from '../hooks/useRespond'

export const ImDown = forwardRef(({ id, sx, wannaId }, ref) => {
	const me = useMe()
	const participantships = useParticipantships(wannaId)
	const myParticipantship = useMemo(
		() => participantships?.find((m) => m.user.id == me?.id),
		[participantships, me?.id],
	)
	const amIDown = myParticipantship != null
	const [imDown, imNotDown] = useRespond(wannaId, myParticipantship?.id)

	return (
		<Chip
			id={id}
			sx={{
				...sx,
				// Solid BG when not down, to draw over the graph edges
				backgroundColor: amIDown ? undefined : (theme) => theme.palette.background.default,
			}}
			ref={ref}
			onClick={(e) => {
				e.stopPropagation()
				if (amIDown) imNotDown()
				else imDown()
			}}
			variant={amIDown ? 'contained' : 'outlined'}
			label={"I'm down!"}
			color='secondary'
			icon={<PanTool />}
		/>
	)
})
