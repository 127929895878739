import * as Sentry from '@sentry/browser'

export function initSentry() {
	Sentry.init({
		dsn: 'https://97655280655e4aad94484cce88b95d58@o1292141.ingest.us.sentry.io/6513587',
		release: import.meta.env.APP_VERSION,
		environment: import.meta.env.MODE,
		integrations: [
			Sentry.feedbackAsyncIntegration({
				autoInject: false,
			}),
		],
	})
}
