import { Avatar, Badge, Divider, ListItem, ListItemAvatar, ListItemButton, ListItemText, Stack } from '@mui/material'
import { useNavigate } from 'react-router'
import { getTimeAgo } from '../../utils/utils.js'

export const NotificationItem = ({ notification, unread }) => {
	const navigate = useNavigate()
	return (
		<Stack>
			<ListItem
				dense
				disablePadding>
				<ListItemButton onClick={() => navigate(notification.path)}>
					<ListItemAvatar>
						<Badge
							color='secondary'
							badgeContent={unread ? '' : null}>
							<Avatar
								alt={notification.title}
								src={notification.pic}
							/>
						</Badge>
					</ListItemAvatar>
					<ListItemText
						primary={notification.title}
						primaryTypographyProps={{ color: 'text.primary' }}
						secondaryTypographyProps={{ component: 'div' }} // Avoid 'div cannot be descendant of p' error
						secondary={
							<div>
								<div>{notification.body}</div>
								<div>{getTimeAgo(notification.timestamp) + ' ago'}</div>
							</div>
						}
					/>
				</ListItemButton>
			</ListItem>
			<Divider />
		</Stack>
	)
}
