import { Stack, Typography } from '@mui/material'
import { LoginButton } from '../../components/LoginButton.jsx'
import { useEffect } from 'react'
import WannaLogo from '../../assets/img/wanna_logo.webp'
import { useMe } from '../../hooks/useMe.js'
import { InteractiveDemo } from './InteractiveDemo.jsx'
import { useIsDemo } from '../../hooks/useIsDemo.js'
import { useNavigate } from 'react-router'

export const Landing = () => {
	const me = useMe(
		// Horrible things will happen if we retrieve a cached `me`
		// and think we're logged in, and then the server says actually no you're not lol.
		true
	)
	const navigate = useNavigate()
	const isDemo = useIsDemo()

	useEffect(() => {
		if (me) navigate('/app', { replace: true })
	}, [me?.id])


	return (
		<Stack
			bgcolor='primary.main'
			justifyContent='center'
			alignItems='center'
			height='100%'>
			<Stack
				color='white'
				justifyContent='space-evenly'
				alignItems='center'
				py={3}
				gap={2}
				maxHeight={'900px'}
				height='100%'
			>
				<Stack
					alignItems='center'
					textAlign='center'
					px={6}>
					<img
						className='landing-logo'
						style={{ width: '100%', maxWidth: '300px' }}
						src={WannaLogo}
					/>
					<Typography fontSize='16px'>From 'What's Up?' to 'I'm Down!' –<br /> Bringing Friends Together, Effortlessly.</Typography>
				</Stack>

				<InteractiveDemo />

				{!isDemo &&
					<Stack
						alignItems='center'
						gap={1}>
						<LoginButton />
					</Stack>
				}
			</Stack>
		</Stack>
	)
}
