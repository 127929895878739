import LOGIN from '../gql/login.gql'
import GET_ME from '../gql/getMe.gql'
import { REFERRER_URL_PARAM } from '../utils/constants.js'
import { GoogleLogin } from '@react-oauth/google'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useApolloClient, useMutation } from '@apollo/client'

export const LoginButton = () => {
	const [search] = useSearchParams()
	const referrerId = search.get(REFERRER_URL_PARAM)

	const apolloClient = useApolloClient()
	const [login] = useMutation(LOGIN)

	const navigate = useNavigate()
	const from = useLocation().state?.from?.pathname

	return (
		<GoogleLogin
			shape='pill'
			text='signin'
			onSuccess={(googleResponse) => {
				apolloClient.clearStore()
				login({
					variables: {
						token: googleResponse.credential,
						referrerId,
					},
					onCompleted: () => {
						// Will affect the result of `useMe`,
						// which the app will respond to
						apolloClient.refetchQueries({
							include: [GET_ME],
						})
						if (from) {
							navigate(from, { replace: true, state: undefined })
						}
					},
				})
			}}
			onFailure={(err) => {
				window.Sentry.captureException(err)
				console.error(err)
				// TODO show to user somehow. But seems rare?
			}}
		/>
	)
}
