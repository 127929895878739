import { GoogleOAuthProvider } from '@react-oauth/google'
import { WannaRouter } from './routes/WannaRouter'
import { AppTheme } from './AppTheme.jsx'
import { BrowserRouter } from 'react-router-dom'
import { initSentry } from './lib/sentry'

initSentry()

export const App = () => {
	return (
		<AppTheme>
			<GoogleOAuthProvider
				clientId={import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_KEY}>
				<BrowserRouter>
					<WannaRouter />
				</BrowserRouter>
			</GoogleOAuthProvider>
		</AppTheme>
	)
}
