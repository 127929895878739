import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import Lightbulb from '@mui/icons-material/Lightbulb'
import Groups from '@mui/icons-material/Groups'
import Logout from '@mui/icons-material/Logout'
import Mood from '@mui/icons-material/Mood'
import Notifications from '@mui/icons-material/Notifications'
import Share from '@mui/icons-material/Share'
import {
	Alert,
	AppBar,
	Avatar,
	Badge,
	Checkbox,
	Divider,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Snackbar,
	Toolbar,
} from '@mui/material'
import { useCallback, useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { ColorModeContext } from '../../AppTheme'
import WannaLogo from '../../assets/img/wanna_logo.webp'
import GET_UNREAD_NOTIFICATIONS from '../../gql/getUnreadNotifications.gql'
import { useLogout } from '../../hooks/useLogout'
import { useMe } from '../../hooks/useMe'
import { share } from '../../utils/utils'
import { REFERRER_URL_PARAM } from '../../utils/constants'

export const HomeToolbar = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const colorMode = useContext(ColorModeContext)
	const [anchorElUser, setAnchorElUser] = useState(null)
	const [open, setOpen] = useState(false)
	const me = useMe()
	const { data: { notifications } = {} } = useQuery(GET_UNREAD_NOTIFICATIONS)
	const logout = useLogout()

	const handleLogout = () => {
		setAnchorElUser(null)
		logout()
	}

	const navigateTo = useCallback(
		(path) => {
			setAnchorElUser(null)
			// Need to pass `from` because home is /app/wannas, not just /app
			navigate(path)
		},
		[navigate, location],
	)

	return (
		<AppBar position='static'>
			<Toolbar>
				<img
					src={WannaLogo}
					style={{
						height: '80%',
						position: 'absolute',
						left: '50%',
						transform: 'translateX(-50%)',
					}}
				/>
				<IconButton
					color='inherit'
					onClick={() => navigateTo('/app/notifications')}>
					<Badge
						color='secondary'
						badgeContent={notifications?.length}>
						<Notifications />
					</Badge>
				</IconButton>
				<IconButton
					sx={{ right: 16, position: 'absolute' }}
					onClick={(e) => setAnchorElUser(e.currentTarget)}>
					<Avatar
						alt={me?.name}
						src={me?.pic}
					/>
				</IconButton>
				<Menu
					sx={{ mt: '40px' }}
					anchorEl={anchorElUser}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					keepMounted
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={anchorElUser !== null}
					onClose={() => setAnchorElUser(null)}>
					<MenuItem onClick={() => navigateTo('/app/friends', {})}>
						<ListItemIcon>
							<Mood />
						</ListItemIcon>
						<ListItemText>Friends</ListItemText>
					</MenuItem>
					<MenuItem onClick={() => navigateTo('/app/circles')}>
						<ListItemIcon>
							<Groups />
						</ListItemIcon>
						<ListItemText>Circles</ListItemText>
					</MenuItem>
					<Divider />
					{/* <MenuItem
						onClick={() =>
							window.open('https://contra.com/nick_van_dyke', '_blank').focus()
						}>
						<ListItemIcon>
							<Work />
						</ListItemIcon>
						<ListItemText>Collaborate</ListItemText>
					</MenuItem> */}
					<MenuItem
						onClick={() => {
							setAnchorElUser(null)
							setOpen(
								!share(
									'Come join me on Wanna!',
									`${window.location.origin}?${REFERRER_URL_PARAM}=${me?.id}`,
								),
							)
						}}>
						<ListItemIcon>
							<Share />
						</ListItemIcon>
						<ListItemText>Share</ListItemText>
					</MenuItem>
					<MenuItem
						onClick={() => {
							// window.open('mailto:nickjvandyke15@gmail.com?subject=Wanna%20feedback&body=', '_blank')
							setAnchorElUser(null)
							Sentry.feedbackAsyncIntegration()
								.createForm({
									colorScheme: 'system',
									formTitle: 'Give Feedback',
									messagePlaceholder:
										'What suggestion do you have?\nOr what issue did you experience?',
									successMessageText: 'Thank you for your feedback!',
									submitButtonLabel: 'Submit',
								})
								.then((form) => {
									form.appendToDom()
									form.open()
								})
						}}>
						<ListItemIcon>
							<Lightbulb />
						</ListItemIcon>
						<ListItemText>Feedback</ListItemText>
					</MenuItem>
					<Divider />
					<MenuItem
						onClick={() => {
							setAnchorElUser(null)
							colorMode.toggle()
						}}>
						<ListItemIcon>
							<Checkbox
								checked={colorMode.mode === 'dark'}
								sx={{ p: 0 }}
							/>
						</ListItemIcon>
						<ListItemText>Dark Mode</ListItemText>
					</MenuItem>
					<Divider />
					<MenuItem onClick={handleLogout}>
						<ListItemIcon>
							<Logout />
						</ListItemIcon>
						<ListItemText>Log out</ListItemText>
					</MenuItem>
				</Menu>
			</Toolbar>
			<Snackbar
				open={open}
				onClose={() => setOpen(false)}
				sx={{ position: 'absolute' }}
				autoHideDuration={2000}>
				<Alert
					onClose={() => setOpen(false)}
					severity='info'
					sx={{ width: '100%' }}>
					Copied share link to clipboard
				</Alert>
			</Snackbar>
		</AppBar>
	)
}
