import { useQuery } from '@apollo/client'
import Mood from '@mui/icons-material/Mood'
import {
	AppBar,
	Dialog,
	DialogTitle,
	IconButton, Stack,
	Toolbar
} from '@mui/material'
import { useState } from 'react'
import { ShareButton } from '../../components/ShareButton'
import { ToolbarTitle } from '../../components/ToolbarTitle'
import { UserSelector } from '../../components/UserSelector'
import GET_FRIENDS from '../../gql/getFriends.gql'
import { useFriendUser } from '../../hooks/useFriendUser'
import PersonAdd from '@mui/icons-material/PersonAdd'
import { useMe } from '../../hooks/useMe'
import { REFERRER_URL_PARAM } from '../../utils/constants'
import { Blurb } from '../../components/Blurb'
import { GoBackButton } from '../../components/GoBackButton'
import { LazyUserGraph } from '../../components/LazyUserGraph'

export const Friends = () => {
	const [searchOpen, setSearchOpen] = useState(false)
	const me = useMe()
	const { data: { friends } = {} } = useQuery(GET_FRIENDS)
	const meWithConnections = me ? { ...me, connections: friends ?? [] } : null
	const { friendUser } = useFriendUser()

	return (
		<Stack
			height='100%'
			position='relative'
			minHeight={0}>
			<AppBar position='static'>
				<Toolbar>
					<GoBackButton />
					<ToolbarTitle>Friends</ToolbarTitle>
					<IconButton
						onClick={() => setSearchOpen(true)}
						color='inherit'>
						<PersonAdd />
					</IconButton>
					<ShareButton
						text={`Come join me on Wanna!`}
						link={`${window.location.origin}?${REFERRER_URL_PARAM}=${me?.id}`}
					/>
				</Toolbar>
			</AppBar>
			{friends?.length === 0 && (
				<Blurb
					icon={<Mood sx={{ width: '64px', height: '64px' }} />}
					text={'try searching for users or sharing an invite link from the top right'}
				/>
			)}
			<LazyUserGraph
				users={(friends ?? [])
					.filter((u, i, a) => a.findIndex((uu) => uu.id === u.id) === i)
					.concat(meWithConnections ? [meWithConnections] : [])}
				height='100%'
			/>
			<Dialog
				open={searchOpen}
				onClose={() => setSearchOpen(false)}>
				<DialogTitle sx={{ pb: 0 }}>Add friends</DialogTitle>
				<UserSelector
					initialChip={'search'}
					hideDefaultChips={true}
					selected={friends?.map((user) => user.id)}
					onToggled={(id) => friendUser(id, !friends.map((user) => user.id).includes(id))}
				/>
			</Dialog>
		</Stack>
	)
}
