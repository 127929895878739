import { makeReference, useMutation } from '@apollo/client'
import ADD_CIRCLE_MEMBER from '../gql/addMember.gql'
import { useMe } from './useMe'

export const useAddMember = (circleId, members) => {
	const me = useMe()
	const [addMember] = useMutation(ADD_CIRCLE_MEMBER, {
		variables: {
			circleId: circleId,
		},
	})

	return {
		addMember: (userId) => {
			addMember({
				variables: {
					userId,
				},
				update: (cache, { data: { addMember } }) => {
					if (userId === me?.id) {
						cache.modify({
							id: cache.identify(makeReference('ROOT_QUERY')),
							fields: {
								circles: (existingCircleIds = []) => {
									const newCircleId = cache.identify(addMember)
									return [{ __ref: newCircleId }, ...existingCircleIds]
								},
							},
						})
					}
				},
				optimisticResponse: {
					__typename: 'Mutation',
					addMember: {
						__typename: 'Circle',
						id: circleId,
						members: members?.concat([me]),
					},
				},
			})
		},
	}
}
