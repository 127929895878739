import { createMockClient, createMockSubscription } from 'mock-apollo-client'
import { useMemo, useState } from 'react'
import GET_WANNA_CHAT from '../../gql/getWannaChat.gql'
import GET_MY_CONNECTIONS from '../../gql/getMyConnections.gql'
import GET_PARTICIPANTSHIPS from '../../gql/getParticipantships.gql'
import GET_CONNECTED_WANNAS from '../../gql/getConnectedWannas.gql'
import GET_WANNA_ACTIVITY from '../../gql/getWannaActivity.gql'
import I_WANNA from '../../gql/iWanna.gql'
import GET_PROFILE from '../../gql/getProfile.gql'
import ON_MESSAGES from '../../gql/onMessages.gql'
import IM_DOWN from '../../gql/imDown.gql'
import IM_NOT_DOWN from '../../gql/imNotDown.gql'
import SEARCH_USERS from '../../gql/searchUsers.gql'
import SEND_MESSAGE from '../../gql/sendMessage.gql'
import ON_PARTICIPANTSHIPS_CHANGED from '../../gql/onParticipantshipsChanged.gql'
import ON_PARTICIPANTSHIPS_STATUS_UPDATED from '../../gql/onParticipantshipStatusUpdated.gql'
import ON_WANNA_CREATED from '../../gql/onWannaCreated.gql'
import GET_UNREAD_NOTIFICATIONS from '../../gql/getUnreadNotifications.gql'
import UPDATE_STATUS from '../../gql/updateStatus.gql'
import GET_ME from '../../gql/getMe.gql'
import GET_FRIENDS from '../../gql/getFriends.gql'
import GET_SETTINGS from '../../gql/getSettings.gql'
import GET_MY_CIRCLES from '../../gql/getMyCircles.gql'
import GET_CIRCLE from '../../gql/getCircle.gql'
import LIKE_MESSAGE from '../../gql/likeMessage.gql'
import GET_NOTIFICATIONS from '../../gql/getNotifications.gql'
import LOGOUT from '../../gql/logout.gql'
import { me, friends, circles, initialWannas, notifications, users } from './demoData'
import { createCache } from '../../lib/apolloCache'

export const useDemoClient = () => {
	const [isLoggedIn, setIsLoggedIn] = useState(true)
	const [wannas, setWannas] = useState(initialWannas)
	// Cache should persist across clients
	const cache = useMemo(() => createCache(), [])
	// We need to return a new client each time the data changes
	// because it doesn't support resetting request handlers.
	const mockClient = useMemo(() => {
		const client = createMockClient({
			cache,
		})

		client.setRequestHandler(GET_ME, () => Promise.resolve({ data: { me: isLoggedIn ? me : null } }))
		client.setRequestHandler(GET_FRIENDS, () => Promise.resolve({ data: { friends } }))
		client.setRequestHandler(SEARCH_USERS, ({ query }) =>
			Promise.resolve({
				data: {
					searchUsers: Object.values(users).filter((user) => user.name.toLowerCase().includes(query.toLowerCase())),
				},
			}),
		)
		client.setRequestHandler(GET_PROFILE, ({ userId }) => Promise.resolve({
			data: {
				user: users[userId],
				friends: friends
			}
		}))
		client.setRequestHandler(GET_MY_CIRCLES, () =>
			Promise.resolve({
				data: {
					circles: circles,
				},
			}),
		)
		client.setRequestHandler(GET_CIRCLE, ({ id }) =>
			Promise.resolve({
				data: {
					circle: circles.find((circle) => circle.id === id),
				},
			}),
		)
		client.setRequestHandler(GET_NOTIFICATIONS, () => Promise.resolve({ data: { notifications } }))
		client.setRequestHandler(GET_UNREAD_NOTIFICATIONS, () =>
			Promise.resolve({
				data: {
					notifications: notifications,
				},
			}),
		)
		client.setRequestHandler(GET_CONNECTED_WANNAS, () =>
			Promise.resolve({
				data: {
					me: {
						id: me.id,
						__typename: 'User',
						wannas: wannas.filter((wanna) => wanna.participantships.some((m) => m.user.id === me.id)),
						connections: me.connections.map((connection) => ({
							...connection,
							wannas: wannas.filter((wanna) => wanna.participantships.some((m) => m.user.id === connection.id)),
						})),
					},
				},
			}),
		)
		client.setRequestHandler(GET_WANNA_ACTIVITY, ({ id }) => {
			const wanna = wannas.find((wanna) => wanna.id === id)
			return Promise.resolve({
				data: {
					wanna,
				},
			})
		})
		client.setRequestHandler(GET_WANNA_CHAT, ({ id }) => {
			const wanna = wannas.find((wanna) => wanna.id === id)
			return Promise.resolve({
				data: {
					wanna: {
						// * Important that we only return the fields it asked for for this and members query
						// Otherwise it gets freaky with the cache or something and spams these queries, using tons of CPU + memory
						id: wanna.id,
						__typename: 'Wanna',
						messages: wanna.messages,
					},
				},
			})
		})
		client.setRequestHandler(GET_PARTICIPANTSHIPS, ({ wannaId }) => {
			const wanna = wannas.find((wanna) => wanna.id === wannaId)
			return Promise.resolve({
				data: {
					wanna: {
						id: wanna.id,
						__typename: 'Wanna',
						participantships: wanna.participantships,
					},
				},
			})
		})
		client.setRequestHandler(GET_MY_CONNECTIONS, ({ wannaId }) =>
			Promise.resolve({
				data: {
					me: me,
				},
			}),
		)
		client.setRequestHandler(I_WANNA, ({ activity }) => {
			const newWannaId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
			const newWanna = {
				id: newWannaId,
				__typename: 'Wanna',
				activity,
				participantships: [{ id: 69, user: me, status: null, created: Date.now(), addedBy: me }],
				created: Date.now(),
				messages: [],
			}
			setWannas((prev) => [newWanna, ...prev])
			return Promise.resolve({
				data: {
					iWanna: newWanna,
				},
			})
		})
		client.setRequestHandler(IM_DOWN, ({ wannaId }) => {
			const targetWanna = wannas.find((wanna) => wanna.id === wannaId)
			const newParticipantship = {
				id: Math.max(...targetWanna.participantships.map((m) => m.id)) + 1,
				__typename: 'Participantship',
				user: me,
				status: null,
			}

			setWannas((prev) =>
				prev
					.filter((wanna) => wanna.id !== wannaId)
					.concat([{ ...targetWanna, participantships: targetWanna.participantships.concat([newParticipantship]) }]),
			)
			return Promise.resolve({
				data: {
					imDown: newParticipantship,
				},
			})
		})
		client.setRequestHandler(IM_NOT_DOWN, ({ wannaId }) => {
			const targetWanna = wannas.find((wanna) => wanna.id === wannaId)
			const targetParticipantship = targetWanna.participantships.find((m) => m.user.id === me.id)
			const participantships = targetWanna.participantships.filter((m) => m.user.id !== me.id)
			setWannas((prev) => prev.filter((wanna) => wanna.id !== wannaId).concat([{ ...targetWanna, participantships }]))
			return Promise.resolve({
				data: {
					imNotDown: {
						id: targetParticipantship.id,
						__typename: 'Participantship',
					},
				},
			})
		})
		client.setRequestHandler(SEND_MESSAGE, ({ wannaId, content }) => {
			const targetWanna = wannas.find((wanna) => wanna.id === wannaId)
			const newMsg = {
				id: Math.max(...wannas.flatMap((w) => w.messages.map((m) => m.id))) + 1,
				__typename: 'Message',
				sent: Date.now(),
				content: content,
				sender: me,
				likes: 0,
				seenBy: targetWanna.participantships
					.filter((m) => m.user.id !== me.id)
					.filter((m) => m.status != null)
					.map((m) => m.user),
			}
			const newWanna = {
				...targetWanna,
				messages: [newMsg, ...targetWanna.messages],
			}
			setWannas((prev) => prev.filter((wanna) => wanna.id !== wannaId).concat([newWanna]))
			return Promise.resolve({
				data: {
					sendMessage: newMsg,
				},
			})
		})
		client.setRequestHandler(LIKE_MESSAGE, ({ messageId }) => {
			const targetWanna = wannas.find((wanna) => wanna.messages.some((m) => m.id === messageId))
			const targetMessage = targetWanna.messages.find((m) => m.id === messageId)
			const newMessage = { ...targetMessage, likes: targetMessage.likes + 1 }
			const newWanna = {
				...targetWanna,
				messages: targetWanna.messages.map((m) => (m.id === messageId ? newMessage : m)),
			}
			setWannas((prev) => prev.filter((wanna) => wanna.id !== targetWanna.id).concat([newWanna]))
			return Promise.resolve({
				data: {
					likeMessage: newMessage,
				},
			})
		})
		client.setRequestHandler(UPDATE_STATUS, ({ status }) =>
			Promise.resolve({
				data: {
					updateStatus: {
						id: me.id,
						__typename: 'Participantship',
						status,
					},
				},
			}),
		)
		client.setRequestHandler(GET_SETTINGS, () =>
			Promise.resolve({
				data: {
					settings: {
						__typename: 'Settings',
						pushSubscriptionEndpoints: [],
					},
				},
			}),
		)

		client.setRequestHandler(LOGOUT, () => {
			setIsLoggedIn(false)
			return Promise.resolve({ data: { logout: me.id } })
		})

		client.setRequestHandler(ON_MESSAGES, () => createMockSubscription())
		client.setRequestHandler(ON_PARTICIPANTSHIPS_CHANGED, () => createMockSubscription())
		client.setRequestHandler(ON_PARTICIPANTSHIPS_STATUS_UPDATED, () => createMockSubscription())
		client.setRequestHandler(ON_WANNA_CREATED, () => createMockSubscription())

		return client
	}, [wannas, friends, circles, me, isLoggedIn])

	return mockClient
}
