import { useMutation } from '@apollo/client'

import { makeReference } from '@apollo/client'
import ADD_FRIEND from '../gql/friendUser.gql'

export function useFriendUser() {
	const [friendUser, friendUserMutation] = useMutation(ADD_FRIEND)

	return {
		friendUser: (id, shouldBeFriend) => {
			friendUser({
				variables: {
					id,
					isFriend: shouldBeFriend,
				},
				optimisticResponse: {
					__typename: 'Mutation',
					friendUser: {
						id: id,
						__typename: 'User',
					},
				},
				update: (cache, { data: { friendUser } }) => {
					cache.modify({
						id: cache.identify(makeReference('ROOT_QUERY')),
						fields: {
							friends: (existingFriendIds = []) => {
								const friendId = cache.identify(friendUser)
								if (shouldBeFriend) {
									return [{ __ref: friendId }, ...existingFriendIds]
								} else {
									return existingFriendIds.filter((friendId) => friendId !== friendId)
								}
							},
						},
					})
				},
			})
		},
		loading: friendUserMutation.loading,
	}
}
