import { Box, Stack, Typography } from '@mui/material'
import { NavAvatar } from '../../components/NavAvatar'
import { useMe } from '../../hooks/useMe'
import { Message } from './Message'
import { getTimeAgo } from '../../utils/utils'

export const MessageGroup = ({ wannaId, group, allMessages }) => {
	const me = useMe()
	const sender = group[0].sender
	const isMe = sender.id === me?.id
	const latestMessage = group[group.length - 1]

	return (
		<Box
			my={1}
			width='100%'
			alignSelf={isMe ? 'flex-end' : 'flex-start'}>
			<Stack
				flex={1}
				gap={0.5}>
				{!isMe && (
					<Stack
						direction={'row'}
						alignItems={'center'}>
						<NavAvatar
							user={sender}
							size='small'
						/>
						<Typography
							px={1}
							variant='caption'
							width={'fit-content'}
							color='text.secondary'>
							{sender.name + ' • ' + getTimeAgo(latestMessage.sent)}
						</Typography>
					</Stack>
				)}
				<Stack
					gap={0.3}
					ml={!isMe ? 2 : undefined}>
					{group.map((message, index) => (
						<Message
							key={message.id}
							wannaId={wannaId}
							message={message}
							position={getPosition(group, index)}
							seenBy={seenByToDisplay(allMessages, message, me?.id)}
						/>
					))}
				</Stack>
			</Stack>
		</Box>
	)
}

function getPosition(messages, index) {
	if (messages.length === 1) return 'alone'
	if (index === 0) return 'top'
	if (index === messages.length - 1) return 'bottom'
	return 'middle'
}

function seenByToDisplay(allMessages, message, meId) {
	const index = allMessages.indexOf(message)
	return allMessages[index].seenBy.filter(
		(seer) =>
			seer.id != meId &&
			!allMessages.slice(index + 1).some((msg) => msg.seenBy.map((seer2) => seer2.id).includes(seer.id)),
	)
}
