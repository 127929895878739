import { useQuery } from '@apollo/client'
import { useEffect } from 'react'
import GET_PARTICIPANTSHIPS from '../gql/getParticipantships.gql'
import ON_PARTICIPANTSHIP_STATUS_UPDATED from '../gql/onParticipantshipStatusUpdated.gql'
import ON_PARTICIPANTSHIPS_CHANGED from '../gql/onParticipantshipsChanged.gql'

export const useParticipantships = (wannaId) => {
	const query = useQuery(GET_PARTICIPANTSHIPS, {
		variables: {
			wannaId: wannaId,
		},
	})

	useEffect(() => {
		const unsub1 = query.subscribeToMore({
			document: ON_PARTICIPANTSHIP_STATUS_UPDATED,
			variables: { wannaId: wannaId },
			// No need for updateQuery here, because the cache is updated automatically
		})

		const unsub2 = query.subscribeToMore({
			document: ON_PARTICIPANTSHIPS_CHANGED,
			variables: { wannaIds: [wannaId] },
		})

		return () => {
			unsub1()
			unsub2()
		}
	}, [wannaId])

	return query.data?.wanna?.participantships
}
