import { useMe } from '../hooks/useMe'
import { ParticipantChip } from './ParticipantChip'
import FlipMove from 'react-flip-move'
import { ImDown } from './ImDown'

export const InlineParticipants = ({ wannaId, participantships }) => {
	const me = useMe()
	const items = participantships
		?.filter((p) => p.user.id !== me?.id)
		?.sort(compare, me?.id)

	return (
		<FlipMove
			style={{
				overflow: 'visible',
				flex: 1,
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'wrap',
				alignItems: 'end',
				gap: '4px',
			}}>
			{/* TODO: ImDown goes at the end when logging in on this page? and it doesn't remove your own chip either if you're already down (rare edge case tho) */}
			{me && (
				<ImDown
					key='imDown'
					wannaId={wannaId}
				/>
			)}
			{items?.map((participantship) => (
				<ParticipantChip
					key={participantship.user.id}
					wannaId={wannaId}
					user={participantship.user}
					status={participantship.status}
				/>
			))}
		</FlipMove>
	)
}

function compare(memA, memB, meId) {
	return (
		(memB.user.id === meId) - (memA.user.id === meId) || // Me first
		(memB.status === 'typing') - (memA.status === 'typing') ||
		(memB.status === 'present') - (memA.status === 'present') ||
		memB.user.name.localeCompare(memA.user.name)
	)
}
