import { Typography } from '@mui/material'

export const ToolbarTitle = ({ children }) => {
	return (
		<Typography
			variant='h6'
			component='div'
			sx={{ flexGrow: 1 }}>
			{children}
		</Typography>
	)
}
