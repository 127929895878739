import Favorite from '@mui/icons-material/Favorite'
import { Box, Stack, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import FlipMove from 'react-flip-move'
import { NavAvatar } from '../../components/NavAvatar'
import { useLike } from '../../hooks/useLike'
import { useMe } from '../../hooks/useMe'
import { getColor } from '../../utils/utils'

export const Message = ({ wannaId, message, position, seenBy, showTimestamp }) => {
	const me = useMe()
	const isMe = message.sender.id === me?.id

	const [triggerable, setTriggerable] = useState(null)
	useEffect(() => {
		const timeout = setTimeout(() => setTriggerable(null), 300)
		return () => clearTimeout(timeout)
	}, [triggerable, setTriggerable])

	const like = useLike(message.id)

	const bgColor = useMemo(() => {
		return getColor(wannaId, message.sender.id, me?.id)
	}, [message.sender.id])
	const borderRadius = useMemo(() => {
		return calcBorderRadius(position, isMe)
	}, [position, isMe])
	const timestamp = useMemo(() => {
		return parseDate(message.sent)
	}, [message.sent])

	const handleClick = () => {
		if (triggerable && !isMe) {
			like(message.likes)
		}

		setTriggerable(Date.now())
	}

	return (
		<Stack
			alignItems='center'
			direction={isMe ? 'row-reverse' : 'row'}
			gap={1}>
			<Stack
				direction='column'
				maxWidth='80%'>
				<Box
					onClick={handleClick}
					width='fit-content'
					py={1}
					px={1.5}
					borderRadius={borderRadius}
					bgcolor={bgColor}>
					<Typography
						variant='body'
						color={isMe ? '#000' : '#FFF'}
						sx={{
							overflowWrap: 'anywhere',
							hyphens: 'auto',
							// Maybe annoying if you wanna e.g. copy an address.
							// But interferes more with double clicking to like.
							// Long-term maybe we go hold to select the msg, with react or copy options.
							userSelect: 'none',
						}}>
						{message.content}
					</Typography>
				</Box>
				<FlipMove>
					<Stack
						mt={-1}
						direction={isMe ? 'row-reverse' : 'row'}
						position='absolute'
						width='100%'
						gap={0.3}>
						{seenBy.map((user) => (
							<NavAvatar
								key={user.id}
								user={user}
								size='xs'
							/>
						))}
					</Stack>
				</FlipMove>
			</Stack>
			{message.likes > 0 && (
				<Stack
					direction='row'
					flexDirection={isMe ? 'row-reverse' : 'row'}
					gap={0.5}>
					<Favorite
						style={{ color: 'red' }}
						onClick={() => like()}
					/>
					<Typography
						variant='caption'
						color='text.secondary'>
						{message.likes}
					</Typography>
				</Stack>
			)}
			{showTimestamp && (
				<Typography
					px={1}
					mr={!isMe ? 0 : 'auto'}
					ml={isMe ? 0 : 'auto'}
					variant='caption'
					color='text.secondary'>
					{timestamp}
				</Typography>
			)}
		</Stack>
	)
}

// https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
function pickTextColorBasedOnBgColorSimple(bgColor, lightColor, darkColor) {
	var color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor
	var r = parseInt(color.substring(0, 2), 16) // hexToR
	var g = parseInt(color.substring(2, 4), 16) // hexToG
	var b = parseInt(color.substring(4, 6), 16) // hexToB
	return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor
}

function calcBorderRadius(position, isMe) {
	if (isMe) {
		if (position === 'alone') return '25px'
		else if (position === 'top') return '25px 25px 0 25px'
		else if (position === 'bottom') return '25px 0 25px 25px'
		else if (position === 'middle') return '25px 0 0 25px'
	} else {
		if (position === 'alone') return '5px 25px 25px 25px'
		else if (position === 'top') return '5px 25px 25px 0'
		else if (position === 'bottom') return '0 25px 25px 25px'
		else if (position === 'middle') return '0 25px 25px 0'
	}
}

function parseDate(timestamp) {
	const today = new Date()
	const sentDate = new Date(timestamp)
	const time = sentDate.toLocaleTimeString([], {
		hour: '2-digit',
		minute: '2-digit',
	})
	if (sentDate.getDate() != today.getDate()) {
		return sentDate.toLocaleDateString([], { weekday: 'short' }) + ' ' + time
	} else {
		return time
	}
}
