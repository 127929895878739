import { useQuery } from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import WorkspacesIcon from '@mui/icons-material/Workspaces'
import { AppBar, Fab, List, Stack, Toolbar } from '@mui/material'
import { useNavigate } from 'react-router'
import { ToolbarTitle } from '../../components/ToolbarTitle'
import GET_MY_CIRCLES from '../../gql/getMyCircles.gql'
import { CircleItem } from './CircleItem'
import { Blurb } from '../../components/Blurb'
import { GoBackButton } from '../../components/GoBackButton'

export const Circles = () => {
	const navigate = useNavigate()
	const { data: { circles } = {} } = useQuery(GET_MY_CIRCLES)

	return (
		<Stack
			height='100%'
			minHeight={0}
			position={'relative'}>
			<AppBar position='static'>
				<Toolbar>
					<GoBackButton />
					<ToolbarTitle>Circles</ToolbarTitle>
				</Toolbar>
			</AppBar>
			{circles?.length === 0 && (
				<Blurb
					icon={<WorkspacesIcon sx={{ width: '64px', height: '64px' }} />}
					text={
						'Circles help you connect with people from shared communities, like a dorm, team, or workplace. Try creating and sharing one!'}
				/>
			)}
			<List
				height='100%'
				sx={{ overflow: 'auto' }}>
				{circles?.map((circle) => (
					<CircleItem
						key={circle.id}
						circle={circle}
					/>
				))}
			</List>
			<Fab
				sx={{
					right: 20,
					bottom: 20,
					position: 'absolute',
				}}
				onClick={() => navigate('/app/circles/create')}
				color='secondary'>
				<AddIcon />
			</Fab>
		</Stack>
	)
}
