import { IconButton } from '@mui/material'
import { useLocation, useNavigate } from 'react-router'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { useCallback } from 'react'

export const GoBackButton = () => {
	const navigate = useNavigate()
	const location = useLocation()

	const nav = useCallback(() => {
		const isThereAPrevPage = location.key !== 'default';
		if (isThereAPrevPage) {
			navigate(-1)
		} else {
			// In the case where user navigated directly to a deeper page
			navigate('/app/wannas', { replace: true })
		}
	}, [navigate, location])

	return (
		<IconButton
			size='large'
			edge='start'
			color='inherit'
			sx={{ mr: 1 }}
			onClick={nav}>
			<ArrowBack />
		</IconButton>
	)
}
