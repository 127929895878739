import { makeReference, useMutation } from '@apollo/client'
import IM_DOWN from '../gql/imDown.gql'
import IM_NOT_DOWN from '../gql/imNotDown.gql'
import { useMe } from './useMe'
import { OPTIMISTIC_ID } from '../utils/constants'
import { useMyConnections } from './useMyConnections'

export const useRespond = (wannaId, myParticipantshipId) => {
	const me = useMe()
	const myConnections = useMyConnections(wannaId)

	// TODO: won't optimistically update the graph because the graph also queries for connections.
	// That seems kinda annoying though so Imma leave it for now.
	const [imDown] = useMutation(IM_DOWN, {
		variables: {
			wannaId,
		},
		optimisticResponse: {
			__typename: 'Mutation',
			imDown: {
				__typename: 'Participantship',
				id: OPTIMISTIC_ID,
				user: {
					...me,
					connections: myConnections ?? [],
				},
				created: Date.now(),
				status: null,
			},
		},
		update: (cache, { data: { imDown } }) => {
			cache.modify({
				id: `Wanna:${wannaId}`,
				fields: {
					participantships: (existingParticipantships = []) => {
						const newParticipantshipRef = makeReference(cache.identify(imDown))
						if (existingParticipantships.some((p) => p.__ref === newParticipantshipRef.__ref)) {
							// May have already updated from subscription
							return existingParticipantships
						}
						return [newParticipantshipRef, ...existingParticipantships]
					},
				},
			})
			cache.modify({
				id: cache.identify(me),
				fields: {
					wannas: (existingWannas = []) => {
						const wannaRef = makeReference(`Wanna:${wannaId}`)
						return [wannaRef, ...existingWannas]
					},
				},
			})
		},
	})
	const [imNotDown] = useMutation(IM_NOT_DOWN, {
		variables: {
			wannaId,
		},
		optimisticResponse: {
			__typename: 'Mutation',
			imNotDown: {
				id: myParticipantshipId,
				__typename: 'Participantship',
			},
		},
		update: (cache, { data: { imNotDown } }) => {
			cache.modify({
				id: cache.identify(me),
				fields: {
					wannas: (existingWannas = []) => {
						const leftWannaId = cache.identify({ __typename: 'Wanna', id: wannaId })
						return existingWannas.filter((w) => w.__ref !== leftWannaId)
					},
				},
			})
			cache.modify({
				id: `Wanna:${wannaId}`,
				fields: {
					participantships: (existingParticipantships = []) => {
						if (!imNotDown) return existingParticipantships
						const deletedParticipantshipRef = cache.identify(imNotDown)
						return existingParticipantships.filter((p) => p.__ref !== deletedParticipantshipRef)
					},
				},
			})
		},
	})

	return [imDown, imNotDown]
}
