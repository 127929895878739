import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import GET_MY_SELECTABLE_USERS from '../gql/getMySelectableUsers.gql'
import { useMe } from './useMe'

export const useSelectableUsers = () => {
	const { data: { friends, circles } = {} } = useQuery(GET_MY_SELECTABLE_USERS)
	const me = useMe()
	const all = useMemo(() => {
		if (!friends || !circles) return null
		return friends
			.concat(circles.flatMap((circle) => circle.members))
			.filter((value, index, self) => self.map((user) => user.id).indexOf(value.id) === index)
			.filter((user) => user.id !== me?.id)
	}, [friends, circles, me?.id])
	
	return { all, friends, circles }
}
