import {
	Button,
	Checkbox,
	Dialog,
	FormControl,
	FormControlLabel,
	FormGroup,
	Paper,
	Stack,
	Typography,
} from '@mui/material'
import { useCallback, useRef, useState } from 'react'
import Typed from 'typed.js'
import WannaLogo from '../../assets/img/wanna_logo.webp'
import { useIWanna } from '../../hooks/useIWanna.js'
import { shuffle } from '../../utils/utils.js'

export const IWanna = ({ isOpen, onClose }) => {
	const [activity, setActivity] = useState('')
	const [notify, setNotify] = useState(true)
	const [allowFriendsOfFriends, setAllowFriendsOfFriends] = useState(true)
	const iWanna = useIWanna()

	const typedRef = useRef()
	const inputRef = useCallback((node) => {
		typedRef.current?.destroy()
		if (!node) return
		typedRef.current = new Typed(node, {
			strings: activites,
			typeSpeed: 70,
			backSpeed: 50,
			backDelay: 500,
			attr: 'placeholder',
			loop: true,
		})
	}, [])

	return (
		<Dialog
			// To nest inside demo device. Seems functionally the same outside of demo.
			// TODO: backdrop covers device frame, not just screen
			disablePortal
			sx={{
				'& .MuiDialog-paper': {
					backgroundColor: 'primary.main',
					color: 'white',
					borderRadius: '16px',
				},
			}}
			open={isOpen}
			onClose={() => {
				setActivity('')
				onClose()
			}}>
			<Stack
				width={'300px'}
				p={2}
				gap={1}
				alignItems={'center'}>
				<img
					src={WannaLogo}
					style={{
						width: '128px',
					}}
				/>
				<Paper
					elevation={2}
					style={{
						background: 'white',
						borderRadius: '8px',
						outline: 'none',
					}}>
					<input
						ref={inputRef}
						style={{
							background: 'white',
							borderRadius: '8px',
							fontSize: '20px',
							padding: '8px',
							textAlign: 'center',
							border: 'none',
							fontFamily: 'inherit',
							outline: 'none',
						}}
						placeholder=''
						value={activity}
						onChange={(e) => setActivity(e.target.value)}
					/>
				</Paper>
				<Button
					sx={{ alignSelf: 'stretch', borderRadius: '8px', fontSize: '16px' }}
					variant='contained'
					color='secondary'
					onClick={() => {
						iWanna(activity, notify, allowFriendsOfFriends)
						onClose()
						setActivity('')
					}}
					disabled={!activity || activity.length > 32}>
					create
				</Button>
				<FormControl sx={{ alignSelf: 'flex-start', p: 1, pb: 0 }}>
					<FormGroup>
						<FormControlLabel
							label={
								<Stack>
									<Typography variant='body'>multiple degrees</Typography>
									<Typography variant='caption'>when a friend joins, let their friends join too</Typography>
								</Stack>
							}
							control={
								<Checkbox
									sx={{ color: 'white' }}
									color='secondary'
									checked={allowFriendsOfFriends}
									onClick={() => setAllowFriendsOfFriends(!allowFriendsOfFriends)}
								/>
							}
						/>
						<FormControlLabel
							label='notify my friends and circles'
							control={
								<Checkbox
									sx={{ color: 'white' }}
									color='secondary'
									checked={notify}
									onClick={() => setNotify(!notify)}
								/>
							}
						/>
					</FormGroup>
				</FormControl>
				<Typography
					variant='caption'
					textAlign={'center'}>
					remains visible until 12 hours of inactivity
				</Typography>
			</Stack>
		</Dialog>
	)
}

const activites = shuffle(
	[
		'get dinner',
		'watch a movie',
		'hit the gym',
		'walk and talk',
		'play board games',
		'go for a ride',
		'read at the park',
	],
	Date.now(),
)
