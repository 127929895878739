import { Box, useTheme } from '@mui/material'
import { MemoryRouter, UNSAFE_LocationContext } from 'react-router'
import { WannaRouter } from '../../routes/WannaRouter'
import '../../assets/css/devices.min.css'
import { IsDemo } from '../../hooks/useIsDemo'

export const demoZoom = 0.7

export const InteractiveDemo = () => {
	const theme = useTheme()

	const height = '100%'
	// device CSS taken from https://github.com/picturepan2/devices.css
	return (
		<div
			className='device device-google-pixel-2-xl'
			style={{
				zoom: demoZoom * 100 + '%',
				flex: 1,
				maxHeight: '832px',
				minHeight: '500px',
			}}>
			<div
				className='device-header'
				style={{ height }}
			/>
			<div
				className='device-frame'
				style={{ height }}>
				{/* relative position for FAB */}
				<Box
					sx={{
						background: theme.palette.background.default,
						height: '100%',
						position: 'relative',
					}}>
					<IsDemo.Provider value={true}>
						<UNSAFE_LocationContext.Provider value={null}>
							{/* Hack to allow nesting MemoryRouter in BrowserRouter https://github.com/remix-run/react-router/issues/7375 */}
							<MemoryRouter initialEntries={['/app/wannas']}>
								<WannaRouter />
							</MemoryRouter>
						</UNSAFE_LocationContext.Provider>
					</IsDemo.Provider>
				</Box>
			</div>
			<div className='device-stripe' />
			<div className='device-sensors' />
			<div className='device-btns' />
			<div className='device-power' />
		</div>
	)
}
