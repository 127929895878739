import { Stack, Typography } from '@mui/material'

export const Blurb = ({ icon, text, cta }) => {
	return (
		<Stack
			p={6}
			gap={1}
			alignItems='center'
			color='text.secondary'
			height='100%'
			sx={{ whiteSpace: 'pre-line' }}
			justifyContent='center'>
			{icon}
			<Typography textAlign='center'>{text}</Typography>
			{cta}
		</Stack>
	)
}
