import { useMutation } from '@apollo/client'
import { LikeMessage } from '../gql/likeMessage.gql'

export const useLike = (messageId) => {
	const [like] = useMutation(LikeMessage, {
		variables: {
			messageId,
		},
	})

	return (numLikes) =>
		like({
			optimisticResponse: {
				__typename: 'Mutation',
				likeMessage: {
					__typename: 'Message',
					id: messageId,
					likes: numLikes + 1,
				},
			},
		})
}
