import LEAVE_CIRCLE from '../gql/leaveCircle.gql'
import { makeReference, useMutation, useQuery } from '@apollo/client'

export const useLeaveCircle = () => {
	const [leaveCircle] = useMutation(LEAVE_CIRCLE, {
		update: (cache, { data: { leaveCircle } }) => {
			if (!leaveCircle) return
			cache.modify({
				id: cache.identify(makeReference('ROOT_QUERY')),
				fields: {
					circles: (existingCircleIds = []) => {
						return existingCircleIds.filter((id) => id !== leaveCircle.id)
					},
				},
			})
		},
		//optimisticResponse: {
		//	__typename: 'Mutation',
		//	leaveCircle: {
		//		id: circleId,
		//		__typename: 'Circle',
		//		members: circle?.members?.filter((member) => member.id !== me?.id),
		//	},
		//},
	})

	return (circleId, onCompleted) => leaveCircle({
		variables: {
			id: circleId,
		},
		onCompleted
	})
}
