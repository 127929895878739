import { useParams } from 'react-router'
import { useFriendUser } from '../../hooks/useFriendUser'
import { useQuery } from '@apollo/client'
import GET_PROFILE from '../../gql/getProfile.gql'
import {
	AppBar,
	Avatar,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	Stack,
	Toolbar,
} from '@mui/material'
import { ToolbarTitle } from '../../components/ToolbarTitle'
import { GoBackButton } from '../../components/GoBackButton'

export const Profile = () => {
	const userId = useParams().id
	const { friendUser, loading: settingFriend } = useFriendUser(userId)
	const {
		data: { friends, user } = {},
		loading: loadingProfile,
	} = useQuery(GET_PROFILE, {
		variables: { userId },
	})
	const isFriend = friends?.some((friend) => friend.id === userId)

	return (
		<Stack height='100%'>
			<AppBar position='static'>
				<Toolbar>
					<GoBackButton />
					<Avatar
						alt={user?.name}
						src={user?.pic}
						sx={{ mr: 1.5 }}
					/>
					<ToolbarTitle>{user?.name}</ToolbarTitle>
				</Toolbar>
			</AppBar>
			<FormControl sx={{ mx: 2, my: 1 }}>
				<FormControlLabel
					label='Friend'
					slotProps={{ typography: { variant: 'h6' } }}
					sx={{ color: 'text.primary' }}
					disabled={loadingProfile || settingFriend}
					control={
						loadingProfile || settingFriend ? (
							<CircularProgress
								size={20}
								sx={{ m: '11px' }} // Align with checkbox location
							/>
						) : (
							<Checkbox
								size='medium'
								onClick={() => friendUser(userId, !isFriend)}
								checked={isFriend ?? false}
							/>
						)
					}
				/>
			</FormControl>
		</Stack>
	)
}
