import { useMutation } from '@apollo/client'
import { AppBar, Box, Button, Checkbox, FormControlLabel, Stack, TextField, Toolbar } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { GoBackButton } from '../../components/GoBackButton'
import { ToolbarTitle } from '../../components/ToolbarTitle'
import CREATE_CIRCLE from '../../gql/createCircle.gql'
import { makeReference } from '@apollo/client'

export const CreateCircle = () => {
	const navigate = useNavigate()
	const [name, setName] = useState('')
	const [inviteOnly, setInviteOnly] = useState(false)
	const [createCircle, createCircleMutation] = useMutation(CREATE_CIRCLE, {
		variables: {
			name: name,
			isPrivate: inviteOnly,
		},
		update(cache, { data: { createCircle } }) {
			cache.modify({
				id: cache.identify(makeReference('ROOT_QUERY')),
				fields: {
					circles: (existingCircleIds = []) => {
						const newCircleId = cache.identify(createCircle)
						return [{ __ref: newCircleId }, ...existingCircleIds]
					},
				},
			})
		},
		onCompleted: (data) => {
			navigate('/app/circles/' + data.createCircle.id, { replace: true })
		},
	})

	return (
		<Stack height='100%'>
			<AppBar position='static'>
				<Toolbar>
					<GoBackButton />
					<ToolbarTitle>Create circle</ToolbarTitle>
				</Toolbar>
			</AppBar>
			<TextField
				label='Name'
				sx={{ m: 2 }}
				value={name}
				autoComplete='off'
				onChange={(e) => setName(e.target.value)}
			/>
			<FormControlLabel
				label='Invite only'
				sx={{ mx: 2 }}
				control={
					<Checkbox
						label='Invite only'
						value={inviteOnly}
						onChange={(e) => setInviteOnly(e.target.checked)}
					/>
				}
			/>
			<Box flex={1} />
			<Button
				variant='contained'
				sx={{ m: 1 }}
				disabled={(name.length < 3 && name.length <= 32) || createCircleMutation.loading}
				onClick={() => createCircle()}>
				Create
			</Button>
		</Stack>
	)
}
