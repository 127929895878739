import { Avatar } from '@mui/material'
import { useNavigate } from 'react-router'

export const NavAvatar = ({ user: { id, name, pic }, size, borderColor }) => {
	const navigate = useNavigate()

	if (size === 'xs') size = 16
	else if (size === 'small') size = 24
	else if (size === 'medium') size = 32
	else if (size === 'large') size = 40
	else size = 40

	return (
		<Avatar
			alt={name}
			src={pic}
			sx={{
				'&:hover': { cursor: 'pointer' },
				border: borderColor ? `2px solid ${borderColor}` : undefined,
				width: size,
				height: size,
				transition: 'all 0.3s ease-in-out',
			}}
			onClick={() => navigate(`/app/people/${id}`)}
		/>
	)
}
