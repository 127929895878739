import { useQuery } from '@apollo/client'
import Add from '@mui/icons-material/Add'
import Groups from '@mui/icons-material/Groups'
import Mood from '@mui/icons-material/Mood'
import Search from '@mui/icons-material/Search'
import {
	Badge,
	Box,
	Button,
	Checkbox,
	Chip,
	CircularProgress,
	InputAdornment,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Stack,
	TextField,
	Typography,
} from '@mui/material'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import SEARCH_USERS from '../gql/searchUsers.gql'
import { useSelectableUsers } from '../hooks/useSelectableUsers'
import { NavAvatar } from './NavAvatar'
import { useMe } from '../hooks/useMe'

const iconOnlySx = {
	'.MuiChip-label': {
		width: 0,
		p: 0,
	},
	'.MuiChip-icon': {
		p: 0,
		m: 1,
	},
}

export const UserSelector = ({
	initialChip,
	hideDefaultChips,
	selected,
	bonusUsers,
	onToggled,
}) => {
	const navigate = useNavigate()
	const [query, setQuery] = useState('')
	const me = useMe()
	const { all: allUsers, friends, circles } = useSelectableUsers()
	const [chip, setChip] = useState(initialChip ?? 'all')

	const { data: { searchUsers } = {}, loading } = useQuery(SEARCH_USERS, {
		variables: {
			query: query,
		},
		skip: query.length < 3,
	})

	const chipOptions = useMemo(() => {
		return [
			{ id: 'search', name: null },
			hideDefaultChips ? null : { id: 'all', name: 'All' },
			hideDefaultChips ? null : { id: 'friends', name: 'Friends' },
		]
			.filter((chip) => chip !== null)
			.concat(circles ?? [])
	}, [hideDefaultChips, circles])

	const all = useMemo(() => {
		if (!allUsers) return null
		return allUsers
			.concat(bonusUsers || [])
			.concat(searchUsers || []) // TODO won't display searched users anymore if they change the query, but it'll still be selected
			.filter(
				(value, index, self) =>
					self.map((user) => user.id).indexOf(value.id) === index,
			)
			.filter((user) => user.id !== me?.id)
	}, [allUsers, me?.id])

	const listedUsers = useMemo(() => {
		let users
		if (chip === 'search') users = searchUsers
		else if (chip === 'all') users = all
		else if (chip === 'friends') users = friends
		else users = circles?.find((c) => c.id === chip)?.members
		users = users?.filter((user) => user.id !== me?.id)?.sort(cmpUsers)
		return users
	}, [chip, friends, circles, me, searchUsers])

	const computeCountForChip = (id) => {
		if (id === 'search')
			return (
				searchUsers?.filter((user) => selected.includes(user.id))?.length ?? 0
			)
		if (id === 'all')
			return all?.filter((user) => selected.includes(user.id))?.length
		if (id === 'friends')
			return friends?.filter((friend) => selected.includes(friend.id))?.length

		return circles
			.find((circle) => circle.id === id)
			.members.filter((user) => user.id !== me?.id)
			.filter((member) => selected.includes(member.id)).length
	}

	return (
		<Stack
			height='100%'
			alignSelf='stretch'
			minHeight={0}>
			<Box width='100%'>
				<Stack
					direction='row'
					gap={0.5}
					pt={2}
					mx={2}
					overflow='scroll'
					sx={{
						'::-webkit-scrollbar': {
							display: 'none',
						},
						scrollbarWidth: 'none',
					}}>
					{chipOptions.map((c) => (
						<Badge
							key={c.id}
							badgeContent={computeCountForChip(c.id)}
							color='primary'>
							<Chip
								label={c.name}
								variant={chip === c.id ? 'filled' : 'outlined'}
								color={chip === c.id ? 'secondary' : 'default'}
								sx={c.name ? {} : iconOnlySx}
								icon={
									c.id === 'all' ? (
										<Groups />
									) : c.id === 'friends' ? (
										<Mood />
									) : c.id === 'search' ? (
										<Search />
									) : null
								}
								onClick={() => setChip(c.id)}
							/>
						</Badge>
					))}
					<Chip
						variant='outlined'
						icon={<Add />}
						onClick={() => navigate('/app/circles')}
						sx={iconOnlySx}
					/>
				</Stack>
			</Box>
			{chip === 'search' ? (
				<Stack pt={2}>
					<TextField
						sx={{ mx: 2 }}
						size='small'
						variant='outlined'
						placeholder='Search for users'
						value={query}
						onChange={(e) => setQuery(e.target.value)}
						autoComplete='off'
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									{
										<CircularProgress
											size={20}
											sx={{ visibility: loading ? 'visible' : 'hidden' }}
										/>
									}
								</InputAdornment>
							),
						}}
					/>
					<List>
						{searchUsers?.map((user) => (
							<ListUser
								key={user.id}
								user={user}
								onToggled={onToggled}
								isSelected={selected.includes(user.id)}
							/>
						))}
					</List>
				</Stack>
			) : all?.length === 0 ? (
				<Stack
					justifyContent={'center'}
					alignItems='center'
					flex={1}>
					<Stack
						direction='column'
						justifyContent='center'
						width='fit-content'
						p={4}
						spacing={1}>
						<Typography
							variant='body'
							textAlign='center'>
							People you know on Wanna will appear here
						</Typography>
						<Button
							variant='contained'
							onClick={() => navigate('/app/people')}>
							Add friends
						</Button>
						<Button
							variant='contained'
							onClick={() => navigate('/app/circles')}>
							Join circles
						</Button>
					</Stack>
				</Stack>
			) : (
				<List sx={{ flex: 1, overflow: 'auto', alignSelf: 'stretch' }}>
					{listedUsers?.map((user) => (
						<ListUser
							key={user.id}
							user={user}
							onToggled={onToggled}
							isSelected={selected.includes(user.id)}
						/>
					))}
				</List>
			)}
		</Stack>
	)
}

const ListUser = ({ user, isSelected, onToggled }) => {
	return (
		<ListItem
			key={user.id}
			dense
			secondaryAction={
				<Checkbox
					edge='end'
					color='secondary'
					checked={isSelected}
					onChange={() => onToggled(user.id)}
				/>
			}>
			<ListItemButton
				disableGutters
				onClick={() => onToggled(user.id)}>
				<ListItemAvatar>
					<NavAvatar user={user} />
				</ListItemAvatar>
				<ListItemText
					primary={user.name}
					secondaryTypographyProps={{
						color: 'green',
						fontWeight: 'bold',
					}}
				/>
			</ListItemButton>
		</ListItem>
	)
}

function cmpUsers(a, b) {
	return a.name.localeCompare(b.name)
}
