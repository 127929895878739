import { useMutation } from '@apollo/client'
import UpdateStatus from '../gql/updateStatus.gql'

export const useStatus = (wannaId) => {
	const [updateStatus] = useMutation(UpdateStatus, {
		variables: {
			wannaId,
		},
	})

	return (status) => {
		updateStatus({
			variables: {
				status,
			},
		})
	}
}
