import { Divider, ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import { useMe } from '../hooks/useMe.js'
import { useMyConnections } from '../hooks/useMyConnections.js'
import { useParticipantships } from '../hooks/useParticipantships.js'
import { getTimeAgo } from '../utils/utils.js'
import { LazyUserGraph } from './LazyUserGraph.jsx'

export const WannaItem = ({ wanna, onClick }) => {
	const me = useMe()
	// Need to retrieve our's separately because we may not already be in the Wanna
	const myConnections = useMyConnections()
	const meWithConnections = me && myConnections ? { ...me, connections: myConnections } : null
	const participantships = useParticipantships(wanna.id)
	const navigate = useNavigate()
	const latestMessage = wanna.messages[0]
	// TODO use wanna.hasUnseenActivity??
	const seenLatestMsg =
		!latestMessage || latestMessage?.sender?.id === me?.id || latestMessage?.seenBy?.some((u) => u.id === me?.id)

	const handleClicked = () => {
		onClick ? onClick() : navigate(`/app/wannas/${wanna.id}`)
	}

	return (
		<Stack>
			<ListItem disablePadding>
				<ListItemButton
					onClick={handleClicked}
					sx={{ pb: 0 }}>
					{/* <ListItemAvatar>
						<NavAvatar user={wanna.creator} />
					</ListItemAvatar> */}
					<ListItemText
						primary={'wanna ' + wanna.activity + '?'}
						primaryTypographyProps={{
							fontWeight: !seenLatestMsg ? 'bold' : undefined,
							variant: 'h6',
							color: 'text.primary',
						}}
						secondaryTypographyProps={{ component: 'div' }} // Avoid 'div cannot be descendant of p' error
						secondary={
							<Stack
								direction='row'
								gap={1}>
								{latestMessage && (
									<Stack
										direction='row'
										flex={1}
										gap={1}
										overflow='hidden'>
										<Typography
											textOverflow={'ellipsis'}
											variant='body2'
											color='text.primary'>
											{latestMessage.sender.id === me?.id ? 'Me' : latestMessage.sender.name.split(' ')[0]}
										</Typography>
										<Typography
											variant='body2'
											noWrap>
											{'- ' + latestMessage.content}
										</Typography>
									</Stack>
								)}
								{getTimeAgo(latestMessage?.sent || wanna.created)}
							</Stack>
						}
					/>
				</ListItemButton>
			</ListItem>
			<LazyUserGraph
				wannaId={wanna.id}
				users={(participantships?.map((p) => p.user)?.filter((user) => user.id !== me?.id) ?? []).concat(
					meWithConnections ? [meWithConnections] : [],
				)}
				statuses={participantships?.reduce((acc, p) => ({ ...acc, [p.user.id]: p.status }), {})}
				useImDown={true}
			/>
			<Divider />
		</Stack>
	)
}
