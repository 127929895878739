import { useApolloClient, useMutation } from '@apollo/client'
import { useNavigate } from 'react-router'
import LOGOUT from '../gql/logout.gql'

export const useLogout = () => {
	const navigate = useNavigate()
	const apolloClient = useApolloClient()
	const [logout] = useMutation(LOGOUT)

	return () =>
		logout({
			onCompleted: () => {
				apolloClient.clearStore().then(() => navigate('/', { replace: true }))
			},
		})
}
