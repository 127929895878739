import React from 'react'

const useAppBarHeight = () => {
	const [appBarHeight, setAppBarHeight] = React.useState(0)
	const appBar = document.querySelector('header.MuiAppBar-root')

	React.useEffect(() => {
		const getAppBarHeight = ([{ target: appBar }]) => {
			appBar && setAppBarHeight(appBar.clientHeight)
		}

		const resizeObserver = new ResizeObserver(getAppBarHeight)

		appBar && resizeObserver.observe(appBar)
	}, [appBar])

	return appBarHeight
}

export default useAppBarHeight
