import React, { useContext } from 'react'

export const IsDemo = React.createContext(false)

export const useIsDemo = () => {
	const isDemo = useContext(IsDemo)
	return isDemo
	// This might be not very performant?
	// const numLogos = document.getElementsByClassName('landing-logo').length
	// return (location.pathname !== '/' && numLogos > 0) || numLogos > 1
}
